import { Button } from 'pages/FieldNotebook/components/Button/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'store/states/snackbar/actions';
import { AnswerSubfield } from 'store/states/forms/types';
import { BoxesContainer, Container, ErrorText, Paragraph } from './styles';
import TextField from '../TextField/TextField';
import { MaskInput } from './MaskInput';
import { NotSuportGeolocationNote } from './NotSuportGeolocationNote/NotSuportGeolocationNote';
import { NeedGeolocationPermitionNote } from './NeedGeolocationPermitionNote/NeedGeolocationPermitionNote';

interface GeolocationProps {
  setValue: (value: string) => void;
  defaultValue: string;
}

interface Coordinates {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export default function Geolocation({
  setValue,
  defaultValue,
}: GeolocationProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [coordinates, setCoordinates] = useState({ lat: '', long: '' });
  const [errorCount, setErrorCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ATTEMPT_LIMIT = 4;

  const notSuportModalRef = useRef<ImperativeModalHandler>(null);
  const handleOpenNotSuportModal = () => notSuportModalRef.current?.open();
  const handleCloseNotSuportModal = () => notSuportModalRef.current?.close();

  const needPermitionModalRef = useRef<ImperativeModalHandler>(null);
  const handleOpenNeedPermitionModal = () =>
    needPermitionModalRef.current?.open();
  const handleCloseNeedPermitionModal = () =>
    needPermitionModalRef.current?.close();

  useEffect(() => {
    if (defaultValue) {
      const coord = defaultValue.split(',');
      setCoordinates({ lat: coord[0], long: coord[1] });
    } else {
      setValue(',');
    }
  }, [defaultValue, setValue]);

  useEffect(() => {
    if (coordinates.lat && coordinates.long) {
      setValue(`${coordinates.lat},${coordinates.long}`);
    }
  }, [coordinates.lat, coordinates.long, setValue]);

  const handleCoordinates = (position: Coordinates) => {
    const { latitude, longitude } = position.coords;
    const lat = latitude.toString();
    const long = longitude.toString();

    setCoordinates({ lat, long });
    setValue(`${latitude},${longitude}`);
  };

  const handleInputLat = (lat: string | AnswerSubfield[]) => {
    setCoordinates({ ...coordinates, lat: lat as string });
  };

  const handleInputLong = (long: string | AnswerSubfield[]) => {
    setCoordinates({ ...coordinates, long: long as string });
  };

  const positionError = (err: any) => {
    if (err.code === 1) {
      handleOpenNeedPermitionModal();
    } else if (errorCount >= ATTEMPT_LIMIT) {
      dispatch(
        showSnackbar({
          message: 'Erro ao marcar localização. Tente novamente mais tarde',
          typeMessage: 'error',
        }),
      );
    } else {
      setErrorCount(errorCount + 1);
    }
    setIsLoading(false);
  };

  const positionSuccess = (position: Coordinates) => {
    handleCoordinates(position);
    setIsLoading(false);
  };

  const getCoordinates = () => {
    if ('geolocation' in navigator) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(positionSuccess, positionError, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 30000,
      });
    } else {
      handleOpenNotSuportModal();
    }
  };

  return (
    <>
      <Container>
        <Button
          variant="outlined"
          color="primary"
          isLoading={isLoading}
          rightIcon={<LocationOnIcon />}
          onClick={getCoordinates}
          disabled={errorCount >= ATTEMPT_LIMIT}
        >
          {t('mark_location')}
        </Button>
        {errorCount >= ATTEMPT_LIMIT && (
          <ErrorText>{t('geolocation_api_error')}</ErrorText>
        )}
        <Paragraph>{t('manual_coordinates')}</Paragraph>
        <BoxesContainer>
          <TextField
            label="Latitude"
            setValue={handleInputLat}
            defaultValue={coordinates.lat}
            inputComponent={MaskInput}
            helperText={t('range_latitude')}
            errorText={t('range_latitude')}
            pattern={
              /^(\+|-)?(?:90(?:\.?(?:0{1,16})?)|(?:[0-9]|[1-8][0-9])(?:\.?(?:[0-9]{1,16})?))$/
            }
          />
          <TextField
            label="Longitude"
            setValue={handleInputLong}
            defaultValue={coordinates.long}
            inputComponent={MaskInput}
            helperText={t('range_longitude')}
            errorText={t('range_longitude')}
            pattern={
              /^(\+|-)?(?:180(?:\.?(?:0{1,16})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.?(?:[0-9]{1,16})?))$/
            }
          />
        </BoxesContainer>
        <Paragraph>{t('gps_information')}</Paragraph>
      </Container>
      <Modal
        ref={notSuportModalRef}
        title={t('unable_geolocation')}
        hasCloseIcon
      >
        <NotSuportGeolocationNote closeModal={handleCloseNotSuportModal} />
      </Modal>
      <Modal
        ref={needPermitionModalRef}
        title={t('unable_geolocation')}
        hasCloseIcon
      >
        <NeedGeolocationPermitionNote
          closeModal={handleCloseNeedPermitionModal}
        />
      </Modal>
    </>
  );
}

import {
  DrawPreviewContainer,
  ImageContainer,
  SignaturePreviewContainer,
} from 'pages/FieldNotebook/HistoricNoteSummary/styles';
import { useTranslation } from 'react-i18next';
import { formatTimestampDate } from 'utils/fieldNotebookUtils/formatters';
import { Input } from '../Input/Input';

interface NoteSelectorProps {
  type: string | undefined;
  answer: string | undefined | null;
}

export function NoteSelector({ type, answer }: NoteSelectorProps) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  if (!answer) {
    return (
      <Input color="#767676" weight={400}>
        {t('unanswered')}
      </Input>
    );
  }

  if (answer.includes('response-content-type=application%2Fpdf')) {
    return <Input>{t('attachedFile')}</Input>;
  }

  if (answer.includes('data:application/pdf')) {
    return <Input>{t('attachedFile')}</Input>;
  }

  if (typeof answer === 'string') {
    if (!answer.startsWith('data:/image/') && type === 'image') {
      return <Input>{answer}</Input>;
    }
  }

  switch (type) {
    case 'date':
      return <Input>{formatTimestampDate(answer, locale)}</Input>;
    case 'image':
      return <ImageContainer src={answer} alt={t('image_of_your_note')} />;
    case 'signature':
      return <SignaturePreviewContainer src={answer} />;
    case 'draw':
      return <DrawPreviewContainer src={answer} />;
    case 'checkbox_group':
      return (
        <Input>
          <strong>{answer}</strong>
        </Input>
      );
    default:
      return <Input>{answer}</Input>;
  }
}

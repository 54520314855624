/* eslint-disable array-callback-return */
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, IconButton } from '@mui/material';
import { Answer, Field } from 'store/states/forms/types';
import { Note } from './styled';
import { Label } from '../Label/Label';
import { NoteSelector } from '../NoteSelector/NoteSelector';

interface IFieldBox {
  index: number | undefined;
  field: Field;
  answer: Answer;
  handleClickEdit: () => void;
}

export function FieldBoxWithSubfields({
  index,
  field,
  answer,
  handleClickEdit,
}: IFieldBox) {
  return (
    <>
      <Note key={index}>
        <Box>
          <>
            <Label>{field?.description} </Label>
            {typeof answer.value !== 'string' &&
              answer.value?.map((value) => (
                <div key={value.value} style={{
                    marginTop: '25px',
                }}>
                  <NoteSelector
                    type={field?.type}
                    answer={value.value as string}
                  />
                  {value.sub_fields?.map((sub_field) => {
                    const matchingSubField = field?.sub_fields?.find(
                      (f) => f.field_id === sub_field.field_id,
                    );

                    return (
                      <div
                        key={sub_field.field_id}
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        {matchingSubField && (
                          <Label>{matchingSubField.description}</Label>
                        )}
                        <p>{sub_field.value}</p>
                      </div>
                    );
                  })}
                </div>
              ))}
          </>
        </Box>
        <IconButton onClick={handleClickEdit}>
          <EditIcon />
        </IconButton>
      </Note>
      <Divider />
    </>
  );
}

import { V4ClassEnum } from 'types/v4ClassEnum';

export enum FormsActionTypes {
  SET_SELECTED_FORM = '@forms/SET_SELECTED_FORM',
  NEXT_STEP = '@forms/NEXT_STEP',
  NEXT_STEP_SUCCESS = '@forms/NEXT_STEP_SUCCESS',
  PREVIOUS_STEP = '@forms/PREVIOUS_STEP',
  SEND_FORM_REQUEST = '@forms/SEND_FORM_REQUEST',
  SEND_FORM_SUCCESS = '@forms/SEND_FORM_SUCCESS',
  SEND_FORM_ERROR = '@forms/SEND_FORM_ERROR',
  EDIT_ANSWER = '@forms/EDIT_ANSWER',
  SET_EDIT_PARAMS = '@forms/SET_EDIT_PARAMS',
  UPDATE_ANSWER = '@forms/UPDATE_ANSWER',
  SET_ANSWERS = '@forms/SET_ANSWERS',
  CLEAR_EDITED_ANSWERS = '@forms/CLEAR_EDITED_ANSWERS',
  CLEAR_INVALID_ANSWERS = '@forms/CLEAR_INVALID_ANSWERS',
  ADD_CUSTOM_ITEM_OPTION = '@forms/ADD_CUSTOM_ITEM_OPTION',
  REMOVE_CUSTOM_ITEM_OPTION = '@forms/REMOVE_CUSTOM_ITEM_OPTION',
  ADD_CUSTOM_INITIAL_ITEMS_OPTIONS = '@forms/ADD_CUSTOM_INITIAL_ITEMS_OPTIONS',
}

export interface FormState {
  selectedForm: Form;
  fillData: FillData;
  answerEdit: AnswerEdit;
  autoGeneratedValue: string;
}

export interface Form {
  form_id: number;
  title: string;
  description: string;
  remember_description: string;
  mandatory: boolean;
  fields: Field[];
  auto_genereated_value: string;
}

export interface FillData {
  step: number;
  totalSteps: number;
  notHasLastQuestion: boolean;
  answers: Answer[];
  fieldStack: number[];
  answerLoading: boolean;
  answerSuccess: boolean;
  answerError: boolean;
}

export interface AnswerEdit {
  answer: Answer;
  field: Field;
}
export interface Field {
  field_id: number;
  type: string;
  placeholder: string;
  description: string;
  optional?: boolean;
  source?: string;
  items: Item[];
  v4_class?: keyof typeof V4ClassEnum;
  summary_title?: string;
  skip_description?: string;
  rules?: {
    needContainsAll: Rule[];
    needContainsSome: Rule[];
    allowIncludeMoreOptions: boolean;
    limitIncludeMoreOptions: number | undefined;
    allowFutureDates: boolean | undefined;
  };
  component_depends?: number[];
  sub_fields?: Field[];
}

export interface Rule {
  field_id: number;
  value: string;
}

export interface Item {
  id: string;
  name: string;
  label: string;
  value: string;
  isCustom?: boolean;
  company_id?: number;
}

export interface Answer {
  field_id: number;
  value: string | null | AnswerSubfield[];
}

export interface AnswerSubfield {
  value: string;
  sub_fields?: Answer[];
}
export interface UpdateAnswerData {
  answer_id: number;
  value: string | null | AnswerSubfield[];
}

export interface AnswerUpdateRequestData {
  answers: UpdateAnswerData[];
}

import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, IconButton } from '@mui/material';
import { Answer, Field } from 'store/states/forms/types';
import { Note } from './styled';
import { Label } from '../Label/Label';
import { NoteSelector } from '../NoteSelector/NoteSelector';

interface IFieldBox {
  index: number | undefined;
  field: Field;
  answer: Answer;
  handleClickEdit: () => void;
}

export function FieldBox({
  index, field, answer, handleClickEdit,
}: IFieldBox) {
  return (
    <>
      <Note key={index}>
        <Box>
          <>
            <Label>
              {field?.description}
              {' '}
            </Label>
            <NoteSelector type={field?.type} answer={answer?.value as string} />
          </>
        </Box>
        <IconButton onClick={handleClickEdit}>
          <EditIcon />
        </IconButton>
      </Note>
      <Divider />
    </>
  );
}

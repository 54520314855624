import { t } from 'i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Field, Form } from 'store/states/forms/types';
import { Historic, HistoricAnswer } from 'store/states/historics/types';
import { getBase64FromImageUrl } from './download-image';
import { formatTimestampDate } from './formatters';
import { logoBooster } from './logoBase64';

interface PdfObject {
  header: string;
  value: string | number;
}

async function formatAnswerByType(
  answer: HistoricAnswer | undefined,
  locale: string,
) {
  if (answer === undefined || answer?.answer === null || answer?.answer === '') {
    return '';
  }
  if (answer.component_type === 'date' && answer?.answer !== null) {
    return formatTimestampDate(answer?.answer as string, locale);
  }

  if (['image', 'draw', 'signature'].includes(answer.component_type)) {
    return getBase64FromImageUrl(answer?.answer as string);
  }

  return `${answer?.answer}`;
}

function getHeader(question: string, description?: string) {
  if (question !== description) {
    return `${question}
    ${description?.replace(/<\/?[^>]+(>|$)/g, '')}
    `;
  }
  return question;
}

export async function formatHistoricToObject(
  historic: Historic,
  formFields: Field[],
  locale: string,
) {
  const fieldsPromises = historic.answers.map(async (answer) => {
    const formatAnswer = await formatAnswerByType(answer, locale);
    const fundedField = formFields.find((field) => field.field_id === answer.field_id);
    const header = getHeader(answer.question, fundedField?.description);
    return {
      header,
      value: formatAnswer,
    };
  });

  const fields = await Promise.all(fieldsPromises);

  const objects = [
    {
      header: t('form_name'),
      value: historic?.form_name,
    },
    {
      header: t('username'),
      value: historic?.user_name,
    },
    {
      header: t('registration_date'),
      value: formatTimestampDate(historic.form_date, locale),
    },
    ...fields,
  ];

  return objects;
}

export function getRowObject(row: PdfObject) {
  if (row?.value.toString().includes('data:image/png;base64,') && row !== undefined) {
    return {
      image: row?.value,
      width: 165,
    };
  }
  return { text: row?.value || '' };
}

export function generatePdfJson(historic: PdfObject[]) {
  const object :any[] = [];
  let fields: PdfObject[] = [];

  historic.forEach((answer, index) => {
    if (fields.length < 3) {
      fields.push(answer);
    } else {
      console.log('else', fields);
    }

    if (fields.length === 3 || index === historic.length - 1) {
      object.push({
        style: 'table',
        color: '#444',
        margin: [0, 20, 0, 0],
        table: {
          widths: [170, 170, 170],
          body: [
            [
              { text: fields[0]?.header || '', style: 'tableHeader' },
              { text: fields[1]?.header || '', style: 'tableHeader' },
              { text: fields[2]?.header || '', style: 'tableHeader' },
            ],
            [
              getRowObject(fields[0]),
              getRowObject(fields[1]),
              getRowObject(fields[2]),
            ],
          ],
        },
        layout: 'noBorders',
      });
      fields = [];
    }
  });

  return object;
}

export async function generateAnswerPdf(historic: Historic, form: Form, locale: string) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const formattedHistoric = await formatHistoricToObject(historic, form.fields, locale);
  const title: any[] = [];
  const footer: any[] = [];

  const content = generatePdfJson(formattedHistoric);

  const docDefinitions = {
    pageSize: 'A4',
    header: [title],
    content: [{
      image: logoBooster,
      width: 150,
    }, content],
    footer: [footer],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      table: {
        margin: [0, 0, 0, 8],
        border: 0,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 10,
    },
  };

  const pdfName = `${historic.form_name}-${historic.monthly_appointment}-${formatTimestampDate(historic.form_date, locale)}`;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  pdfMake.createPdf(docDefinitions).download(pdfName);
}

/* eslint-disable array-callback-return */
import DatePicker from 'components/Forms/DatePicker/DatePicker';
import Decimal from 'components/Forms/Decimal/Decimal';
import Draw from 'components/Forms/Draw/Draw';
import FieldAutoGenerated from 'components/Forms/FieldAutoGenerated/FieldAutoGenerated';
import Geolocation from 'components/Forms/Geolocation/Geolocation';
import RadioBox from 'components/Forms/RadioBox/RadioBox';
import Signature from 'components/Forms/Signature/Signature';
import TextField from 'components/Forms/TextField/TextField';
import UploadFile from 'components/Forms/UploadFile/UploadFile';
import { AnswerSubfield, Field, Item } from 'store/states/forms/types';
import FieldCheckbox from 'components/Forms/FieldCheckbox/FieldCheckbox';
import FieldCheckboxGroup from 'components/Forms/subfields/FieldCheckboxGroup';

interface FormSelectorProps {
  field: Field;
  value: string;
  setValue: (value: string) => void;
  onCustomItemAdded: (items: Item) => void;
  onCustomItemRemoved: (items: Item) => void;
}

export default function FormSelector({
  field,
  value,
  setValue,
  onCustomItemAdded,
  onCustomItemRemoved,
}: FormSelectorProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isAnswerSubfieldArray = (arr: any[]): arr is AnswerSubfield[] => arr.every((val) => typeof val === 'object' && 'value' in val);
  
  const setMultiplesValues = (newValues: string[] | AnswerSubfield[]) => {
    if (isAnswerSubfieldArray(newValues)) {
      setValue(JSON.stringify(newValues))
    } else {
      setValue(`\t${newValues.join('\t')}`);
    }
  };

  const getMultipleValues = () => {
    if (!value.includes('sub_fields')) {
      if (value === '') return [];
      return value.split('\t').slice(1);
    }
    const valueSubfields = JSON.parse(value) as AnswerSubfield[]
    return valueSubfields.map((item) => item.value as string);
    };

  switch (field.type) {
    case 'text':
      return <TextField setValue={setValue} defaultValue={value as string} />;
    case 'textArea':
      return (
        <TextField
          label={field.summary_title}
          isExtraAnnotation
          setValue={setValue}
          defaultValue={value as string}
        />
      );
    case 'number':
      return (
        <TextField
          label={field.summary_title}
          setValue={setValue}
          defaultValue={value as string}
          type="number"
        />
      );
    case 'radio': {
      return (
        <RadioBox
          setValue={setValue}
          defaultValue={value as string}
          onCustomItemAdded={onCustomItemAdded}
          onCustomItemRemoved={onCustomItemRemoved}
          field={field}
        />
      );
    }

    case 'date':
      return (
        <DatePicker
          label={field.summary_title}
          setValue={setValue}
          defaultValue={value}
          futureDateEnabled={field.rules?.allowFutureDates}
        />
      );
    case 'image':
      return <UploadFile setValue={setValue} defaultValue={value} />;
    case 'geolocation':
      return <Geolocation setValue={setValue} defaultValue={value} />;
    case 'draw':
      return <Draw setValue={setValue} defaultValue={value} />;
    case 'signature':
      return <Signature setValue={setValue} defaultValue={value} />;
    case 'decimal':
      return <Decimal setValue={setValue} defaultValue={value} />;
    case 'checkbox':
      return (
        <FieldCheckbox
          field={field}
          setMultipleValues={setMultiplesValues}
          defaultValues={getMultipleValues()}
          onCustomItemAdded={onCustomItemAdded}
          onCustomItemRemoved={onCustomItemRemoved}
        />
      );

    case 'auto_generated':
      return <FieldAutoGenerated defaultValue={value} />;
    case 'checkbox_group':
      return (
        <FieldCheckboxGroup
          defaultValue={value as string}
          defaultValues={getMultipleValues()}
          setMultipleValues={setMultiplesValues}
          field={field}
          onCustomItemAdded={onCustomItemAdded}
          onCustomItemRemoved={onCustomItemRemoved}
        />
      );
    default:
      return (
        <div style={{ color: 'black' }}>
          Error &nbsp;
          {field.type}
        </div>
      );
  }
}

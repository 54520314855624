import {
  forwardRef,
  useState,
  ForwardRefRenderFunction,
  ReactNode,
  useImperativeHandle,
} from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { themeOptions } from 'assets/styles/theme';
import {
  ModalContainer,
  ModalContainerPaper,
  ModalContainerTop,
  ModalContentContainer,
  ModalTitleContainer,
} from './styles';

export interface ImperativeModalHandler {
  open: () => void;
  close: () => void;
}

interface ImperativeModalProps {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  hasCloseIcon?: boolean;
  width?: number;
}

const ImperativeModal: ForwardRefRenderFunction<
  ImperativeModalHandler,
  ImperativeModalProps
> = ({
  children,
  title,
  subtitle,
  hasCloseIcon,
  width 
}, ref) => {
  const [openedModal, setOpenedModal] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setOpenedModal(true),
    close: () => setOpenedModal(false),
  }));

  if (openedModal) {
    return (
      <ModalContainer>
        <ModalContainerPaper style={{ width: `${width}px` }}> {/* Aplicando width dinamicamente */}
          <ModalTitleContainer>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {title && <Typography variant='h6'>{title}</Typography>}
              {hasCloseIcon && (
              <ModalContainerTop>
                <CloseIcon color='action' onClick={() => setOpenedModal(false)} />
              </ModalContainerTop>
              )}
            </div>
            {subtitle && (
              <Typography variant='subtitle1' component='p' style={{ color: themeOptions.palette.grey[500] }}>
                {subtitle}
              </Typography>
            )}
          </ModalTitleContainer>
          <ModalContentContainer>{children}</ModalContentContainer>
        </ModalContainerPaper>
      </ModalContainer>
    );
  }

  return <></>;
};


export const Modal = forwardRef(ImperativeModal);

import { Historic, HistoricAnswer } from 'store/states/historics/types';
import { t } from 'i18next';
import { ExportToCsv } from 'export-to-csv';
import { Field, Form } from 'store/states/forms/types';
import { formatTimestampDate } from './formatters';

const hideFields = ['image', 'draw', 'signature'];

export function createHeader(form: Form) {
  const fields = form.fields
    .map((field) => {
      if (!hideFields.includes(field.type)) {
        return String(field.summary_title);
      }
      return null;
    })
    .filter(Boolean);

  const header = [
    t('form_name'),
    t('username'),
    t('registration_date'),
    ...fields,
  ] as string[];

  return header;
}

export function setOptionsCsv(csvFilename: string, form: Form) {
  const options = {
    filename: csvFilename,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: ',',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: createHeader(form),
  };

  return options;
}

function formatAnswerByType(
  answer: HistoricAnswer | undefined,
  field: Field,
  locale: string,
) {
  if (answer === undefined) { return ''; }

  if (field.type === 'date' && answer?.answer !== null) { return formatTimestampDate(answer?.answer as string, locale); }

  return (answer?.answer === '' || answer?.answer === null) ? '' : `"${answer?.answer}"`;
}

export function formatAnswers(
  historics: Historic[],
  form: Form,
  locale: string,
) {
  const slicedFields = form.fields.filter(
    (field) => !hideFields.includes(field.type),
  );

  const formattedAnswers = historics.map((historic) => {
    const formattedDate = formatTimestampDate(historic.form_date, locale);

    const formattedHistoricAnswers = slicedFields.map((field) => {
      const answer = historic.answers.find(
        (ans) => field.field_id === ans.field_id,
      );
      return formatAnswerByType(answer, field, locale);
    });

    return {
      form_name: historic.form_name,
      user_name: historic.user_name,
      form_date: formattedDate,
      answers: formattedHistoricAnswers,
    };
  });

  return formattedAnswers;
}

export function handleDataToGenerateCsv(
  historics: Historic[],
  form: Form,
  locale: string,
) {
  const answers = formatAnswers(historics, form, locale);
  const options = setOptionsCsv(t('notes'), form);

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(answers);
}

import { Box, Button as MUIButton } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AnswerSubfield, Field, Item } from 'store/states/forms/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import AddSharp from '@mui/icons-material/AddSharp';
import { themeOptions } from 'assets/styles/theme';
import { Container, Label } from './styles';
import TextFieldSubfields from './components/TextFieldSubfields';
import SelectSubfields from './components/SelectSubfields';
import FieldCheckboxSubfields from './components/FieldCheckboxSubfields';
import {
  ImperativeModalHandler,
  ModalSubfield,
} from './components/Modal/ModalSubfield';

interface SubfieldsProps {
  defaultValue: string;
  defaultValues: string[];
  field: Field;
  onCustomItemAdded: (items: Item) => void;
  onCustomItemRemoved: (items: Item) => void;
  setMultipleValues: (value: string[] | AnswerSubfield[]) => void;
}

export default function FieldCheckboxGroup({
  defaultValue,
  field,
  defaultValues,
  onCustomItemAdded,
  onCustomItemRemoved,
  setMultipleValues,
}: SubfieldsProps) {
  const { t } = useTranslation();

  const modalRef = useRef<ImperativeModalHandler>(null);

  const handleOpenModal = () => modalRef.current?.open();
  const handleCloseModal = () => modalRef.current?.close();

  const getLabelCheckbox = (value: string) => {
    const label = field.items.find((item) => item.value === value);
    return label?.label;
  };

  const valueSubfield =
    typeof defaultValue === 'string'
      ? defaultValue.includes('sub_fields')
        ? (JSON.parse(defaultValue) as AnswerSubfield[])
        : ([] as AnswerSubfield[])
      : defaultValue;

  const checkTypeField = (
    subfield: Field,
    subIndex: number,
    value: AnswerSubfield,
    index: number,
  ) => {
    const selectedAnswerSubfield = valueSubfield.find(
      (v) => v.value === value.value,
    );

    const subfieldValue =
      selectedAnswerSubfield?.sub_fields?.find(
        (sub) => sub.field_id === subfield.field_id,
      )?.value || '';

    switch (subfield.type) {
      case 'text':
        return (
          <TextFieldSubfields
            key={`${index}-${subIndex}`}
            defaultValue={subfieldValue as string}
            label={subfield.summary_title}
            setValue={(newValue: string) => {
              const updatedAnswerSubfields = valueSubfield.map((ans) => {
                if (ans.value === value.value) {
                  const existingSubFields = ans.sub_fields || [];
                  const subfieldIndex = existingSubFields.findIndex(
                    (sub) => sub.field_id === subfield.field_id,
                  );

                  if (subfieldIndex !== -1) {
                    const updatedSubFields = existingSubFields.map((sub) =>
                      sub.field_id === subfield.field_id
                        ? { ...sub, value: newValue }
                        : sub,
                    );
                    return {
                      ...ans,
                      sub_fields: updatedSubFields,
                    };
                  }
                }
                return ans;
              });
              setMultipleValues(updatedAnswerSubfields);
            }}
            type={subfield.type}
          />
        );
      case 'radio':
        return (
          <div
            style={{
              marginTop: '15px',
            }}
          >
            <SelectSubfields
              key={`${index}-${subIndex}`}
              field={subfield}
              defaultValue={subfieldValue as string}
              setValue={(newValue: string) => {
                const updatedAnswerSubfields = valueSubfield.map((ans) => {
                  if (ans.value === value.value) {
                    const existingSubFields = ans.sub_fields || [];
                    const subfieldIndex = existingSubFields.findIndex(
                      (sub) => sub.field_id === subfield.field_id,
                    );

                    if (subfieldIndex !== -1) {
                      const updatedSubFields = existingSubFields.map((sub) =>
                        sub.field_id === subfield.field_id
                          ? { ...sub, value: newValue }
                          : sub,
                      );
                      return {
                        ...ans,
                        sub_fields: updatedSubFields,
                      };
                    }
                  }
                  return ans;
                });
                setMultipleValues(updatedAnswerSubfields);
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      {valueSubfield.length < 1 ? (
        <Label htmlFor="contained-button-file" marginTop={70}>
          <input
            type="button"
            id="contained-button-file"
            onClick={handleOpenModal}
          />
          <MUIButton variant="outlined" component="span">
           {t('selectFromList')}
          </MUIButton>
        </Label>
      ) : (
        <div>
          <Label htmlFor="contained-button-file" marginTop={70}>
            <MUIButton
              onClick={handleOpenModal}
              variant="outlined"
              component="span"
            >
              {t('add')}
              <AddSharp
                sx={{
                  marginLeft: '2px',
                }}
              />
            </MUIButton>
          </Label>

          <Box
            sx={{
              maxHeight: '300px',
              overflowY: 'auto',
              marginTop: '20px',
              paddingBottom: '52px',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            {typeof defaultValue.includes('sub_fields') &&
              valueSubfield.map((value, index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <strong>{getLabelCheckbox(value.value)}</strong>
                    <Box
                      sx={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: '0',
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: `${themeOptions.palette?.error.dark}`,
                      }}
                      onClick={() =>
                        setMultipleValues(
                          valueSubfield.filter((item) => item !== value),
                        )
                      }
                    >
                      <DeleteIcon />
                    </Box>
                  </Box>

                  <div style={{ marginTop: '12px', marginBottom: '10px' }}>
                    {field.sub_fields?.map((subfield, subIndex) =>
                      checkTypeField(subfield, subIndex, value, index),
                    )}
                  </div>

                  {index !== valueSubfield.length - 1 && (
                    <Box
                      sx={{
                        borderBottom: '1px solid #ccc',
                        marginBottom: '20px',
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
          </Box>
        </div>
      )}

      <ModalSubfield
        ref={modalRef}
        hasCloseIcon
        title={t('Selecionar na lista')}
        width={427}
      >
        <FieldCheckboxSubfields
          field={field}
          defaultValues={defaultValues}
          setMultipleValues={setMultipleValues}
          onCustomItemAdded={onCustomItemAdded}
          onCustomItemRemoved={onCustomItemRemoved}
          defaultValue={valueSubfield}
          isSubfield
        />
        <Label htmlFor="contained-button-file" marginTop={0}>
          <MUIButton
            onClick={handleCloseModal}
            variant="contained"
            component="span"
            endIcon={<CheckCircleIcon />}
          >
             {t('save')}
          </MUIButton>
        </Label>
      </ModalSubfield>
    </Container>
  );
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { AnswerSubfield, Field, FormState } from 'store/states/forms/types';
import { formatTimestampDate } from 'utils/fieldNotebookUtils/formatters';
import openFullIcon from 'assets/icons/open_full.svg';
import { Input } from '../Input/Input';
import { InputDate } from '../InputDate/InputDate';
import { ThumbnailImage } from '../ThumbnailImage/ThumbnailImage';
import { MapsAnswer } from '../MapsAnswer/MapsAnswer';
import { ModalOpener } from './styled';

interface HistoricInputSelectorProps {
  field: Field;
  answer?: string | null;
  formDate: number;
  openImageModal: (imageurl: string, title: string, subtitle: string) => void;
  openThumbnailModal: (
    signature: string,
    title: string,
    subtitle: string,
  ) => void;
  openDrawModal: (draw: string, title: string, subtitle: string) => void;
}

export function HistoricInputSelector({
  field,
  answer,
  formDate,
  openImageModal,
  openThumbnailModal,
  openDrawModal,
}: HistoricInputSelectorProps) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  const { selectedForm } = useSelector<ReduxState, FormState>(
    (state) => state.forms,
  );
  const formattedDate = formatTimestampDate(formDate, locale);

  const isEmpty = answer === '' || answer === null || answer === undefined;

  if (
    field.type === 'image' &&
    answer?.includes('response-content-type=application%2Fpdf')
  ) {
    return (
      <Input>
        <a target="_blank" href={answer} download rel="noreferrer">
          {t('viewFile')}
        </a>
      </Input>
    );
  }

  if (isEmpty) {
    return (
      <Input color="#767676" weight={400}>
        {t('unanswered')}
      </Input>
    );
  }

  switch (field.type) {
    case 'date': {
      return <InputDate date={answer as string} />;
    }

    case 'image': {
      return (
        <ThumbnailImage
          image={answer as string}
          onClick={() =>
            openImageModal(
              answer as string,
              t('image_of_your_note'),
              `${selectedForm.title}: ${formattedDate}`,
            )
          }
        >
          <div>
            <p>{t('tap_to_full_screen')}</p>
            <img src={openFullIcon} alt="open full icon" />
          </div>
        </ThumbnailImage>
      );
    }
    case 'geolocation': {
      const coordinates = (answer as string).split(',');
      return (
        <MapsAnswer
          lat={coordinates[0]}
          long={coordinates[1]}
          subtitle={`${selectedForm.title}: ${formattedDate}`}
        />
      );
    }
    case 'signature': {
      return (
        <ModalOpener
          image={answer as string}
          onClick={() =>
            openThumbnailModal(
              answer as string,
              `${t('show_signature_modal_title')}`,
              formattedDate,
            )
          }
        >
          <div>
            <p>{t('tap_to_full_screen')}</p>
            <img src={openFullIcon} alt="open full icon" />
          </div>
        </ModalOpener>
      );
    }
    case 'draw': {
      return (
        <ModalOpener
          image={answer as string}
          onClick={() =>
            openDrawModal(
              answer as string,
              `${t('show_signature_modal_title')}`,
              formattedDate,
            )
          }
        >
          <div>
            <p>{t('tap_to_full_screen')}</p>
            <img src={openFullIcon} alt="open full icon" />
          </div>
        </ModalOpener>
      );
    }

    case 'checkbox_group': {
      if (typeof answer === 'string' && answer.includes('sub_fields')) {
        const answerSubfield = JSON.parse(answer) as AnswerSubfield[];
        if (answerSubfield.length > 0) {
          return (
            <div>
              {answerSubfield.map((value, index) => (
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <p key={index}>{value.value}</p>
                  {value.sub_fields!.length > 0 && <p>|</p>}
                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                    }}
                  >
                    {value.sub_fields?.map((valueSubfield, indexSubfield) => (
                      <>
                        <p>{valueSubfield.value}</p>
                        {indexSubfield < value.sub_fields!.length - 1 && <p>|</p>}
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          );
        }
      } 
      if (typeof answer !== 'string') {
        const answerSubfield = answer as AnswerSubfield[];
        if (answerSubfield.length > 0) {
          return (
            <div>
              {answerSubfield.map((value, index) => (
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <p key={index}>{value.value}</p>
                  {value.sub_fields!.length > 0 && <p>|</p>}
                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                    }}
                  >
                    {value.sub_fields?.map((valueSubfield, indexSubfield) => (
                      <>
                        <p>{valueSubfield.value}</p>
                        {indexSubfield < value.sub_fields!.length - 1 && <p>|</p>}
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          );
        }
      }

      return null;
    }

    case 'checkbox': {
      const allItemsAnswereds = (answer as string).split('\t').slice(1);
      const answerString = allItemsAnswereds
        .map(
          (itemAnswer) =>
            field.items.find((fieldItem) => fieldItem.value === itemAnswer)
              ?.label ?? itemAnswer,
        )
        .join(', ');
      return <Input>{answerString}</Input>;
    }
    default:
      return <Input>{answer}</Input>;
  }
}

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Field } from 'store/states/forms/types';

interface SelectSubfieldsProps {
  error?: boolean;
  setValue: (value: string) => void;
  defaultValue: string;
  variant?: 'outlined' | 'standard' | 'filled';
  field: Field;
}

export default function SelectSubfields({
  error,
  setValue,
  defaultValue,
  variant = 'outlined',
  field,
}: SelectSubfieldsProps) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (value === defaultValue) {
      setValue('');
    } else {
      setValue(value);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{field.description}</InputLabel>
      <Select
        error={error}
        value={defaultValue}
        label={field.description}
        variant={variant}
        onChange={handleChange}
      >
        {field.items.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
